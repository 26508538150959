<template>
  <div>
    <!-- SECTION modal-modify-add-travel-fly-passenger -->
    <validation-observer
      ref="refFormObserver"
      #default="{invalid,pristine}"
    >
      <b-modal
        id="modal-modify-add-travel-fly-passenger"
        title="Thêm thẻ thành viên"
        title-class="text-airline font-medium-4 font-weight-bolder"
        centered
        size="md"
        no-close-on-backdrop
        @show="showHandle"
      >
        <template #modal-footer="{cancel}">
          <div class="d-flex justify-content-end w-100">
            <b-button
              size="md"
              variant="outline-secondary"
              class="center rounded-pill mr-2"
              @click="cancel()"
            >
              {{ $t('reservation.back') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="btn-gradient border-right-0"
              pill
              :disabled="invalid||(pristine && !createdNewMemberShipCard)"
              @click="submitAddTravelFly"
            >
              <div class="d-flex-center px-25">
                {{ $t('reservation.confirm') }}
              </div>
            </b-button>
          </div>
        </template>

        <b-card
          v-if="!isEmpty(passengerData)"
          header-bg-variant="light-info"
          header-class="py-1"
          no-body
        >
          <template #header>
            <span class="text-airline font-weight-bolder">
              {{ passengerData.lastName }} {{ passengerData.firstName }} {{ passengerData.title }}
            </span>
            <small class="text-warning">({{ $t(`reservation.${passengerData.paxType}`) }})</small>
          </template>
          <b-row class="mx-0 mt-75">
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Hãng hàng không"
                rules="required"
              >
                <b-form-group
                  :label="$t('reservation.airline')"
                  label-for="memberFly-airline"
                >
                  <v-select
                    v-model="airline"
                    input-id="memberFly-airline"
                    :options="getAirlineOptionsGroupByTrips(reservationsData)"
                    style="min-width: 180px;"
                    label="text"
                    class="membership-airline-select select-size-sm"
                    :clearable="false"
                    :placeholder="$t('reservation.placeholderSelect')"
                    :reduce="val => val.value"
                  >
                    <template #option="data">
                      <span
                        class="my-25 font-weight-bold"
                        :class="isMobileView ? 'font-small-1' : 'font-small-4'"
                      >
                        {{ data.text }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span
                        class="font-weight-bolder text-nowrap"
                        :class="isMobileView ? 'font-small-1' : 'font-small-4'"
                      >
                        {{ data.text }}
                      </span>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="BSV"
                rules="required"
              >
                <b-form-group
                  :label="$t('reservation.cardNumber')"
                  label-for="memberFly-number"
                >
                  <b-form-input
                    id="memberFly-number"
                    v-model="newCSV"
                    class="font-medium-1 text-dark font-weight-bold"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('reservation.placeholderInput')"
                    :state="getValidationState(validationContext) === false ? false : null"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <div
              v-if="createdNewMemberShipCard"
              class="px-1"
            >
              <div
                v-for="(ttv, indexTtv) of createdNewMemberShipCard"
                :key="indexTtv"
                class="mb-50"
              >
                <div
                  v-if="(ttv.cardNumber === 0) || !isNumber(ttv.cardNumber)"
                  class="text-center"
                >
                  <span class="text-danger font-weight-bolder">
                    - {{ $t('reservation.errCreateMembershipCardAirline') }} {{ ttv.airline.label }}:
                    {{ ttv.msgError ? getErr(ttv.msgError) : '' }}
                  </span>
                </div>

                <div v-else>
                  <small class="text-success"> - {{ $t('reservation.newMembershipCreated', {airline: `${ttv.airline.label}`}) }}</small>
                  <div class="text-dark font-medium-2 font-weight-bolder ml-1">
                    {{ `${ttv.cardNumber}` }}
                  </div>
                </div>
              </div>

              <b-alert
                variant="warning"
                class="mt-1"
                show
              >
                <div class="alert-body text-center">
                  <span class="font-medium-1 text-airline">{{ $t('reservation.successCreateTTV') }}</span>
                </div>
              </b-alert>
            </div>
          </b-row>

          <b-row class="mb-1 d-flex-center">
            <span class="mr-75">{{ $t('reservation.dontHaveMembershipCardYet') }}</span>
            <b-button
              variant="flat-info"
              size="sm"
              pill
              @click="handleOpenModalCreateMemberShipCard($event)"
            >
              {{ $t('reservation.createNew') }}
            </b-button>
          </b-row>
        </b-card>

        <b-spinner v-else />
      </b-modal>
    </validation-observer>

    <MembershipCardModal
      :member-fly-data.sync="memberFlyData"
      :data-search-flight="dataSearchFlight"
      :airline-lists="getAirlineList"
      :pax-data="passengerData"
      :place="'modify'"
    />
  </div>
</template>

<script>
import {
  BAlert,
  BModal,
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  ref, toRefs, watch, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
import {
  skyTeamAirlineGroupOptions,
  oneWorldAirlineGroupOptions,
  starAllianceAirlineGroupOptions,
} from '@/constants/selectOptions'
import store from '@/store'

import { trimInput } from '@core/comp-functions/forms/formatter-input'
import formValidation from '@core/comp-functions/forms/form-validation'

import MembershipCardModal from '@flightv2/reservation/MembershipCardModal.vue'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BAlert,
    BModal,
    BCard,
    BSpinner,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,

    MembershipCardModal,
    vSelect,
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
    passengerData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const {
      getBookingData,
      modifyFrequentFlyer,
    } = useReservationHandle()

    const { passengerData } = toRefs(props)

    const newCSV = ref(null)
    const createdNewMemberShipCard = ref(null)
    const memberFlyData = ref(null)
    const airline = ref(null)
    // const getAirlineNameByCode = code => store.getters['globalConfig/getAirlineNameByCode'](code) || code

    const errList = {
      USERNAME_ALREADY_IN_USE: 'Tên chủ thẻ đã tồn tại',
      PHONE_NUMBER_OR_EMAIL_ALREADY_IN_USE: 'Số điện thoại hoặc email đã được sử dụng',
    }

    const getErr = errCode => errList[errCode] || ''

    const getAirlineList = computed(() => {
      if (getBookingData.value) {
        return ['1S', '1S_CTRL'].includes(getBookingData.value.source) ? ['VN'] : [getBookingData.value.source]
      }
      return []
    })

    const dataSearchFlight = ref({
      type: 'OW',
      flights: [
        {
          departDate: convertISODateTime(getBookingData.value?.itineraries[0][0]?.departure?.at).dateFilter,
        },
      ],
    })

    const setDefaultAirline = airlineCode => {
      if (!airline.value) {
        airline.value = airlineCode
      }
    }

    function getAirlineOptionsGroupByTrips(bookingData) {
      const result = new Set()
      const addedValues = new Set() // Check giá trị trước khi thêm vào result

      if (!isEmpty(bookingData.itineraries)) {
        const airlineArr = bookingData.itineraries.flat().map(item => item.airline)
        const airlineGroups = [
          skyTeamAirlineGroupOptions,
          oneWorldAirlineGroupOptions,
          starAllianceAirlineGroupOptions,
        ]

        for (const groupOptions of airlineGroups) {
          if (groupOptions.some(item => airlineArr.includes(item.value))) {
            groupOptions.forEach(item => {
              result.add(item)
              addedValues.add(item.value)
            })
            setDefaultAirline(airlineArr[0])
            return Array.from(result)
          }
        }

        airlineArr.forEach(item => {
          const text = store.getters['globalConfig/getAirlineNameByCode'](item) || item
          if (!addedValues.has(item)) { // Kiểm tra xem giá trị đã được thêm vào result chưa
            result.add({ value: item, text })
            addedValues.add(item)
          }
        })

        setDefaultAirline(airlineArr[0])
      }

      return Array.from(result)
    }

    function showHandle() { // for do something when open modal
      newCSV.value = null
      airline.value = null
    }

    const resetContactDataToEdit = () => {
      newCSV.value = null
    }

    const { refFormObserver, getValidationState } = formValidation(
      resetContactDataToEdit,
    )

    function submitAddTravelFly() {
      const passenger = passengerData.value

      const updateData = {
        frequentFlyer: {
          airline: airline.value,
          supplierCode: airline.value,
          number: String(newCSV.value),
        },
        ope: {
          operation: 'ADD',
          id: '',
        },
      }

      // show modal confirm to modify
      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: 'Xác nhận thêm thẻ thành viên?' },
      })

      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Thêm',
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')

            modifyFrequentFlyer(getBookingData.value, passenger, updateData.ope, updateData.frequentFlyer)
              .then(() => {
                this.$bvModal.hide('modal-modify-add-travel-fly-passenger')
              })
              .catch(error => {
                console.error({ error })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    watch(() => memberFlyData.value, value => {
      if (value) {
        if (value.length && value.length === 1) {
          const source = ['1S', '1S_CTRL'].includes(getBookingData.value.source) ? 'VN' : 'QH'
          const ttvBySource = value.find(i => i.airline.code === source)
          createdNewMemberShipCard.value = value

          if (ttvBySource) {
            newCSV.value = ttvBySource.cardNumber
          }
        }

        if (value.length && value.length > 1) {
          const source = ['1S', '1S_CTRL'].includes(getBookingData.value.source) ? 'VN' : 'QH'
          const ttvBySource = value.find(i => i.airline.code === source)
          createdNewMemberShipCard.value = value

          if (ttvBySource) {
            newCSV.value = ttvBySource.cardNumber
          }
        }
      }
    })

    function handleOpenModalCreateMemberShipCard(event) {
      this.$root.$emit('bv::show::modal', 'modal-register-member-ship-card', event)
    }

    return {
      newCSV,
      getBookingData,
      memberFlyData,
      dataSearchFlight,
      createdNewMemberShipCard,
      airline,

      showHandle,
      formatCurrency,
      submitAddTravelFly,
      convertISODateTime,
      getAirlineOptionsGroupByTrips,
      handleOpenModalCreateMemberShipCard,

      isNumber,
      isEmpty,
      refFormObserver,
      getValidationState,
      // getAirlineNameByCode,
      getAirlineList,
      getErr,
      trimInput,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
